<template>
    <div>
        <b-row class="content-header">
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="float-left mb-0">Resellers Panel</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-card>
            <b-row>
                <b-col md="5">
                    <b-form-group label="Email" label-for="email">
                        <b-form-input
                            id="email"
                            v-model="email"
                            trim
                            :disabled="emailDis"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="5">
                    <b-form-group label="API KEY" label-for="apikey">
                        <b-form-input
                            id="apikey"
                            v-model="apikey"
                            trim
                            :disabled="apikeyDis"
                        />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button
                        variant="primary"
                        block
                        class="mt-2"
                        @click="loginBtn"
                        :disabled="loginDis || email == '' || apikey == ''"
                        v-if="!logged"
                        >Login
                    </b-button>
                    <b-button
                        variant="danger"
                        block
                        class="mt-2"
                        @click="logoutBtn"
                        :disabled="logoutDis"
                        v-else
                        >Logout
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <div v-if="logged">
            <b-card no-body>
                <b-card-header>
                    <b-card-title
                        >Reseller Balance: ${{ balance }}
                    </b-card-title>
                    <b-card-text
                        ><b-button
                            class="btn-icon"
                            variant="outline-primary"
                            @click="onAddMoney"
                            >+
                        </b-button></b-card-text
                    >
                </b-card-header>
                <b-table
                    responsive
                    :fields="f_price"
                    :items="price"
                    class="mb-0"
                    hover
                >
                    <template #cell(action)="data">
                        <b-button
                            variant="gradient-primary"
                            block
                            @click="onGenerate(data.item.plan_id)"
                            :disabled="genDis"
                            >Generate</b-button
                        >
                    </template>
                </b-table>
            </b-card>
            <b-card no-body>
                <b-card-header>
                    <b-card-title>List of vouchers </b-card-title>
                    <b-card-text>
                        <b-form-input
                            v-model="filter"
                            class="d-inline-block mr-1"
                            placeholder="Search..."
                        />
                    </b-card-text>
                </b-card-header>
                <b-overlay :show="show" rounded="sm" variant="transparent">
                    <b-table
                        responsive
                        :fields="f_vouchers"
                        :items="vouchers"
                        :per-page="perPage"
                        :filter="filter"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                        class="mb-0"
                        hover
                        striped
                    >
                        <template #cell(used)="data">
                            <span
                                >{{
                                    data.item.is_redeemed == true ? "Yes" : "No"
                                }}
                            </span>
                        </template>
                    </b-table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        align="center"
                        class="mt-1"
                        ><template #prev-text>
                            <feather-icon size="18" icon="ChevronLeftIcon" />
                        </template>
                        <template #next-text>
                            <feather-icon size="18" icon="ChevronRightIcon" />
                        </template>
                    </b-pagination>
                </b-overlay>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BCardHeader,
    BCardTitle,
    BPagination,
    BOverlay,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BButton,
        BTable,
        BCardHeader,
        BCardTitle,
        BPagination,
        BOverlay,
        FeatherIcon,
    },
    data() {
        return {
            email: "",
            apikey: "",
            sitekey: process.env.VUE_APP_RECAPTCHA,
            loginDis: false,
            emailDis: false,
            apikeyDis: false,
            logged: false,
            logoutDis: false,
            balance: 0,
            plans: "",
            price: [],
            f_price: ["plan_id", "plan", "price", "action"],
            vouchers: [],
            f_vouchers: ["created_at", "voucher", "plan", "expires_at", "used"],
            filter: "",
            perPage: 15,
            rows: 0,
            currentPage: 1,
            show: false,
            genDis: false,
            count: 0,
        };
    },
    methods: {
        showToast(message, variant) {
            this.$bvToast.toast(message, {
                title: "Message",
                variant: variant,
                toaster: "b-toaster-bottom-right",
                solid: true,
            });
        },
        onAddMoney() {
            this.showToast(
                "Please contact us to Add Money. We will add automatic payment soon.",
                "primary"
            );
        },
        async onGenerate(id) {
            const comfirmDelete = await this.$bvModal.msgBoxConfirm(
                "Are you sure?"
            );
            if (comfirmDelete) {
                this.genDis = true;
                this.$store
                    .dispatch("generateVouchers", {
                        email: this.email,
                        apikey: this.apikey,
                        plan_id: id,
                    })
                    .then((res) => {
                        this.genDis = false;
                        if (!res.data.success) {
                            this.showToast(res.data.message, "danger");
                        } else {
                            this.showToast(res.data.message, "primary");
                            this.loginBtn();
                        }
                    })
                    .catch((err) => console.log(err));
            }
        },
        loginBtn() {
            this.loginDis = true;
            this.$store
                .dispatch("loginReseller", {
                    email: this.email,
                    apikey: this.apikey,
                })
                .then((res) => {
                    this.loginDis = false;
                    if (!res.data.success) {
                        this.showToast(res.data.message, "danger");
                    } else {
                        this.balance = res.data.info.balance;
                        this.plans = res.data.info.plans;
                        this.emailDis = true;
                        this.apikeyDis = true;
                        this.logged = true;
                        this.fetchPlans();
                        this.showVouchers();
                    }
                })
                .catch(() => {
                    this.loginDis = false;
                });
        },

        showVouchers() {
            this.show = true;
            this.$store
                .dispatch("showVouchers", {
                    email: this.email,
                    apikey: this.apikey,
                })
                .then((res) => {
                    this.vouchers = res.data.data;
                    this.rows = this.vouchers.length;
                    this.show = false;
                })
                .catch((err) => console.log(err));
        },

        logoutBtn() {
            this.emailDis = false;
            this.apikeyDis = false;
            this.logged = false;
            this.plans = "";
            this.balance = 0;
            this.vouchers = [];
            this.price = [];
            this.apikey = "";
        },

        filterbyID(json, id) {
            return json.filter(function (element) {
                if (element.id == id) {
                    return true;
                } else {
                    return false;
                }
            });
        },

        fetchPlans() {
            if (this.price.length == 0) {
                this.$store
                    .dispatch("fetchPlans")
                    .then((res) => {
                        var arr = this.plans.split(",");
                        arr.forEach((element) => {
                            let info = element.split("=");
                            var plan = this.filterbyID(res.data, info[0]);
                            if (plan.length != 0) {
                                this.price.push({
                                    plan_id: info[0],
                                    plan: plan[0].name,
                                    price: "$" + info[1],
                                });
                            }
                        });
                    })
                    .catch((err) => console.log(err));
            }
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>

<style></style>
